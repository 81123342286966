import React from 'react';
import { string } from 'prop-types';

import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { propTypes, LINE_ITEM_TAX } from '../../util/types';

import css from './OrderBreakdown.module.css';

const LineItemRefundTaxesMaybe = props => {
  const { lineItems, marketplaceName, intl } = props;

  const refund = lineItems.find(item => item.code === LINE_ITEM_TAX && item.reversal);

  return refund ? (
    // <div className={css.lineItem}>
    //   <span className={css.itemLabel}>
    //     {/* <FormattedMessage id="OrderBreakdown.refundCustomerFee" values={{ marketplaceName }} /> */}
    //   </span>
    //   <span className={css.itemValue}>{formatMoney(intl, refund.lineTotal)}</span>
    // </div>
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage id="OrderBreakdown.refundTax" />
      </span>
      <span className={css.itemValue}>{formatMoney(intl, refund.lineTotal)}</span>
    </div>
  ) : null;
};

LineItemRefundTaxesMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  marketplaceName: string.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemRefundTaxesMaybe;
