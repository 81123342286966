// InfoIcon.js (New External Component with Tooltip)
import React, { useState } from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import css from './InfoIcon.module.css';

const InfoIcon = ({ lineItems , value }) => {
  // const [showInfo, setShowInfo] = useState(false);
  return (
    <div className={css.infoContainer}>
        <div id="tooltip" className={css.tooltip}>
        {value}
        </div>
    </div>
  );
};

export default InfoIcon;
