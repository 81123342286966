import React, { useState } from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_TAX } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import classNames from 'classnames';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes, LINE_ITEM_HOUR } from '../../util/types';

import css from './OrderBreakdown.module.css';
import InfoIcon from './InfoIcon';

const LineItemTaxesMaybe = props => {
  const { lineItems, intl, transaction, isProvider, code } = props;
  const { UUID, Money } = sdkTypes;

  const taxesLineItem = lineItems.find(item => item.code === LINE_ITEM_TAX && !item.reversal);
  const [showInfo, setShowInfo] = useState(false);
  const isNightly = code === LINE_ITEM_NIGHT;
  const isDaily = code === LINE_ITEM_DAY;
  const isHourly = code === LINE_ITEM_HOUR;
  const translationKey = isNightly
    ? 'OrderBreakdown.baseUnitNight'
    : isDaily
    ? 'OrderBreakdown.baseUnitDay'
    : isHourly
    ? 'OrderBreakdown.baseUnitHour'
    : 'OrderBreakdown.baseUnitQuantity';

  // Find correct line-item for given code prop.
  // It should be one of the following: 'line-item/night, 'line-item/day', 'line-item/hour', or 'line-item/item'
  // These are defined in '../../util/types';
  const unitPurchase = lineItems.find(item => item.code === code && !item.reversal);

  const quantity = unitPurchase ? unitPurchase.quantity.toString() : null;
  const unitPrice = unitPurchase ? formatMoney(intl, unitPurchase.unitPrice) : null;
  const total = unitPurchase ? formatMoney(intl, unitPurchase.lineTotal) : null;

  return taxesLineItem ? (
    <div
      onMouseOver={() => setShowInfo(true)}
      onMouseLeave={() => setShowInfo(false)}
      className={classNames(css.lineItem, css.underLine)}
    >
      {showInfo && (
        <InfoIcon
          lineItems={lineItems}
          value={
            <FormattedMessage
              id="OrderBreakdown.finalPriceWithoutOwnerCommission"
              values={{ value: `${total}` }}
            />
          }
          showInfo={showInfo}
        />
      )}
      <span className={css.itemLabel}>
        <FormattedMessage id="OrderBreakdown.taxes" />
      </span>
      <span className={css.itemValue}>{formatMoney(intl, taxesLineItem.lineTotal)}</span>
    </div>
  ) : null;
};

LineItemTaxesMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemTaxesMaybe;
